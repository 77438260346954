import {FormattedMessage, useIntl} from 'react-intl';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '../../Common/Button';
import {EditIconGreen} from '../../../constants/images';
import NoteItem, {NoteItemProps} from '../../../features/Note/NoteItem';
import {useEffect, useState} from 'react';
import {NoteEntity} from '../../../types/common/NoteEntity';
import NoteForm, {NoteFormData} from '../../../features/Note/NoteForm';
import {useCreateSaleNoteMutation} from '../../../features/Note/noteApi';

export type SalesNotesProps = {
  notes?: NoteEntity[];
  saleId: number;
  onNoteAdded?: (newNote: NoteEntity) => void;
  onNoteUpdated?: NoteItemProps['onNoteUpdated'];
};

const SaleNotes = ({
  notes,
  saleId,
  onNoteAdded,
  onNoteUpdated,
}: SalesNotesProps) => {
  const intl = useIntl();
  const [createNote, {data, isSuccess}] = useCreateSaleNoteMutation();
  const [addForms, setAddForms] = useState<{id: number}[]>([]);
  const [selectedAddFormId, setSelectedAddFormId] = useState<
    number | undefined
  >();

  const onAddClick = () => {
    setAddForms([...addForms, {id: Math.round(Math.random() * 10 ** 6)}]);
  };

  const removeAddForm = (addFormId: number) => {
    setAddForms(addForms.filter(form => form.id !== addFormId));
  };

  const handleAddNote = ({note}: NoteFormData, addFormId: number) => {
    createNote({
      note,
      saleId,
      showErrorMessage: true,
      successMessage: intl.formatMessage({id: 'notes.message.created'}),
    });
    setSelectedAddFormId(addFormId);
  };

  useEffect(() => {
    if (isSuccess && data && onNoteAdded) {
      onNoteAdded(data);
      if (selectedAddFormId) {
        removeAddForm(selectedAddFormId);
        setSelectedAddFormId(undefined);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  return (
    <div className="card details-card sales-summary mx-0 mb-3">
      <div className="card-body pb-4">
        <Typography variant="h4" className="mb-3">
          <FormattedMessage id="actions.note" />
        </Typography>
        {Array.isArray(notes) && notes.length === 0 && (
          <Typography variant="body2" className="mb-2">
            <FormattedMessage id="notes.no_note" />
          </Typography>
        )}
        {Array.isArray(notes) && notes.length > 0 && (
          <Box
            display="flex"
            flexDirection="column"
            gridGap={10}
            paddingBottom={2}
          >
            {notes.map((item, index) => (
              <NoteItem key={index} note={item} onNoteUpdated={onNoteUpdated} />
            ))}
          </Box>
        )}
        {addForms.map((form, i) => (
          <NoteForm
            key={form.id}
            form={`NoteFormAdd-${form.id}`}
            onCancel={() => removeAddForm(form.id)}
            onSubmit={(values: NoteFormData) => handleAddNote(values, form.id)}
          />
        ))}
        <Button
          small
          link
          primary
          icon={<EditIconGreen />}
          title={intl.formatMessage({id: 'actions.add'})}
          onClick={onAddClick}
        />
      </div>
    </div>
  );
};

export default SaleNotes;
